import CartActionTypes from '../actionTypes/cart'
import { getCouponCodeFromShopifyCart, getDiscountFromShopifyCart, getItemsFromShopifyCart, getSubtotalFromShopifyCart, getTotalFromShopifyCart, sortCartItems } from './helpers';

const initialState = {
  client: null,
  checkoutUrl: null,
  shopifyCart: null,
  items: [],
  subTotalAmount: null,
  totalAmount: null,
  discountAmount: null,
  currencyCode: null,
  couponCode: null,
  isUpdating: false,
  isUpdated: false,
  isAdded: false,
  removing: [],
  removed: []
};

export default (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case CartActionTypes.INIT_ACTION:
      return {
        ...state,
        client: payload.client,
        shopifyCart: payload.shopifyCart,
        checkoutUrl: payload.shopifyCart?.checkoutUrl,
        items: getItemsFromShopifyCart(payload.shopifyCart),
        subTotalAmount: getSubtotalFromShopifyCart(payload.shopifyCart),
        totalAmount: getTotalFromShopifyCart(payload.shopifyCart),
        discountAmount: getDiscountFromShopifyCart(payload.shopifyCart),
        removing: [],
        removed: []
      };
    case CartActionTypes.UPDATING_ACTION:
      return {
        ...state,
        ...payload,
        isUpdating: true,
      };
    case CartActionTypes.UPDATE_ACTION:
      return {
        ...state,
        isUpdating: false,
        shopifyCart: payload.shopifyCart,
        checkoutUrl: payload.shopifyCart?.checkoutUrl,
        items: getItemsFromShopifyCart(payload.shopifyCart),
        subTotalAmount: getSubtotalFromShopifyCart(payload.shopifyCart),
        totalAmount: getTotalFromShopifyCart(payload.shopifyCart),
        discountAmount: getDiscountFromShopifyCart(payload.shopifyCart),
        isUpdated: payload.isUpdated,
        couponCode: getCouponCodeFromShopifyCart(payload.shopifyCart),
        isToggleBasket: payload.isToggleBasket,
        isAdded: payload.isAdded,
        removing: [],
        removed: []
      };
    case CartActionTypes.UPDATED_ACTION:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
}

export function getCheckout(store) {
  return {
    items: store.items,
    checkoutUrl: store.checkoutUrl,
    couponCode: store.couponCode,
    isUpdated: store.isUpdated
  }
}

export function getCart(store) {
  return store
}

export function getJupiterCart(store) {
  const { 
    checkoutUrl, 
    shopifyCart, 
    items, 
    subTotalAmount, 
    totalAmount, 
    discountAmount,
    couponCode 
  } = store

  return {
    checkoutUrl: checkoutUrl,
    shopifyCart: shopifyCart,
    items: sortCartItems(items),
    subTotalAmount: subTotalAmount,
    totalAmount: totalAmount,
    discountAmount: discountAmount,
    couponCode: couponCode
  }
}

export function isUpdating(store) {
  return store.isUpdating
}

export function isUpdated(store) {
  return store.isUpdated
}

export function getRemovingItems(store) {
  return store.removing;
}

export function getRemovedItems(store) {
  return store.removed;
}

export function getItemsCount(store) {
  let count = 0;

  if (store.items) {
    for (const item of store.items) {
      count += item.quantity
    }
  }

  return count;
}

export function isAdded(store) {
  return store.isAdded
}

export function isToggleBasket(store) {
  return store.isToggleBasket
}
