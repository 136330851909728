export const getItemsFromShopifyCart = (shopifyCart) => {
  if (shopifyCart.lines?.edges?.length > 0) {
    return sortCartItems(shopifyCart.lines.edges.map(edge => edge.node))
  }

  return []
}

export const getSubtotalFromShopifyCart = (shopifyCart) => {
  let subtotalAmountInPence = 0

  if (shopifyCart.lines?.edges?.length > 0) {
    for (const line of shopifyCart.lines.edges) {
      if (line.node.sellingPlanAllocation?.checkoutChargeAmount?.amount) {
        const priceInPence = parseFloat(line.node.sellingPlanAllocation.checkoutChargeAmount.amount) * 100
        subtotalAmountInPence = subtotalAmountInPence + (priceInPence * line.node.quantity)
      } else if (line.node.merchandise?.price?.amount) {
        const priceInPence = parseFloat(line.node.merchandise.price.amount) * 100
        subtotalAmountInPence = subtotalAmountInPence + (priceInPence * line.node.quantity)
      }
    }
  }

  return subtotalAmountInPence / 100
}

export const getTotalFromShopifyCart = (shopifyCart) => {
  let totalAmountInPence = 0

  if (shopifyCart.cost?.totalAmount?.amount) {
    totalAmountInPence = parseFloat(shopifyCart.cost.totalAmount.amount) * 100
  }

  if (shopifyCart.cost?.totalTaxAmount?.amount) {
    const taxAmountInPence = parseFloat(shopifyCart.cost.totalTaxAmount.amount) * 100

    totalAmountInPence = totalAmountInPence - taxAmountInPence
  }

  return totalAmountInPence / 100
}

export const getDiscountFromShopifyCart = (shopifyCart) => {
  let discountAmountInPence = 0

  if (shopifyCart.lines?.edges?.length > 0) {
    for (const line of shopifyCart.lines.edges) {
      let lineDiscountInPence = 0

      if (line.node.discountAllocations?.length > 0) {
        for (const discount of line.node.discountAllocations) {
          if (discount.discountedAmount) {
            lineDiscountInPence = lineDiscountInPence + (parseFloat(discount.discountedAmount.amount) * 100)
          }
        }
      }

      discountAmountInPence = discountAmountInPence + lineDiscountInPence
    }
  }

  if (shopifyCart.discountAllocations?.length > 0) {
    let cartDiscountInPence = 0

    for (const discount of shopifyCart.discountAllocations) {
      if (discount.discountedAmount) {
        cartDiscountInPence = cartDiscountInPence + (parseFloat(discount.discountedAmount.amount) * 100)
      }
    }

    discountAmountInPence = discountAmountInPence + cartDiscountInPence
  }

  return discountAmountInPence / 100
}

export const getCouponCodeFromShopifyCart = (shopifyCart) => {
  if (shopifyCart.discountCodes?.length > 0) {
    return shopifyCart.discountCodes[0].code
  }

  return null
}

export const sortCartItems = (itemsArray) => {
  itemsArray.forEach((item) => {
    let itemHandle = item.merchandise.product.handle
    if (itemHandle.includes("shampoo")) {
      item.order = 1
    }
    if (itemHandle.includes("conditioner")) {
      item.order = 2
    }
    if (itemHandle.includes("serum")) {
      item.order = 3
    }
    if (itemHandle.includes("elixir")) {
      item.order = 4
    }
    if (itemHandle.includes("mask")) {
      item.order = 5
    }
    if (itemHandle.includes("brush")) {
      item.order = 6
    }
    if (itemHandle.includes("daily-scalp-essential")) {
      item.order = 7
    }
    if (!item.order) {
      item.order = 100
    }
  })

  itemsArray.sort((a, b) => {
    const aDate = new Date(a.added)
    const bDate = new Date(b.added)
    if ( a.order > b.order) return 1;
    if ( a.order < b.order) return -1;
    if ( aDate > bDate) return 1;
    if ( aDate < bDate) return -1;
  })

  return itemsArray
}