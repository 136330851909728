/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export { default as wrapRootElement } from "./src/store/reduxWrapper"

export const onServiceWorkerUpdateReady = () => window.location.reload()

export const onClientEntry = () => {
  const DEFAULT_BRANCH_NAME = "main"

  const branch = process.env.GATSBY_BRANCH;

  const branchValue = (branch && typeof branch !== "undefined") ? branch : DEFAULT_BRANCH_NAME;

  window.localStorage.setItem("branch", branchValue);
}
